'use client'

import { auth, db } from "@/connectors/firebase";
import { Leads } from "@/interfaces/firebase";
import { collection, onSnapshot, query, Timestamp } from "firebase/firestore";
import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import useAuth from "@/hooks/useAuth";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { sendMessageToAPI } from "@/services/api";
import UserBadge from "@/components/UserBadge";
import { useNotificationBanner } from "./notificationBannerContext";
import { getUser } from "@/services/users";

const LeadsContext = React.createContext({
    leads: [] as Leads[],
});

export function useLeads() {
    return useContext(LeadsContext);
}

export function LeadsProvider({ children }: { children: React.ReactNode }) {
    const { currentUser, userLoggedIn } = useAuth()
    const queryClient = useQueryClient()
    const { addNotificationBanner } = useNotificationBanner()
    const { data } = useQuery(
        {
            queryKey: ["leads"],
            initialData: () => {
                return queryClient.getQueryData(["leads"]) as Leads[] ?? []
            },
            staleTime: Infinity
        }
    )
    const [leads, setLeads] = useState<Leads[]>(data)
    const [currentTier, setCurrentTier] = useState("")

    useEffect(() => {
        if (currentUser) {
            setCurrentTier(currentUser.tierAchieved || "")
        } else {
            setCurrentTier("")
        }
    }, [currentUser])

    useEffect(() => {
        if (data) setLeads(data)
    }, [data])

    useEffect(() => {
        if (!userLoggedIn) return
        let unsub = () => { };
        try {
            const needsRef = query(collection(db, "leads"))
            unsub = onSnapshot(needsRef, async (querySnapshot) => {
                let allLeads: Leads[] = []
                for (const snap of querySnapshot.docs) {
                    let data = snap.data() as Leads;
                    allLeads.push({ ...data, id: snap.id })
                }

                setLeads(allLeads)

                getUser(currentUser.id).then((user) => {

                    const userLeadsCount = allLeads.filter(f => f.creator == user.id).length

                    console.log(userLeadsCount, user.tierAchieved)

                    if (userLeadsCount >= 5 && user.tierAchieved !== "Connector" && user.tierAchieved !== "Super Connector") {
                        updateTierAchived("Connector")
                        return
                    }

                    if (userLeadsCount >= 10 && user.tierAchieved !== "Super Connector") {
                        updateTierAchived("Super Connector")
                        return
                    }
                })
                queryClient.setQueryData(["leads"], allLeads);
            });
        } catch (error: any) {
            console.log(error)
        }
        return unsub
    }, [currentUser.id, userLoggedIn])


    const BannerContent = ({ badge }: { badge: string }): ReactElement => {
        return (
            <>
                <p className="text-white font-bold text-lg">New Badge unlocked:</p>
                <UserBadge badge={badge} className="self-start" />
                {
                    badge == "Connector" ?
                        <p className="text-white font-medium text-sm">For contributing with <span className="font-bold">5 leads</span> on the app, this badge will be available on your profile.</p>
                        :
                        <p className="text-white font-medium text-sm">For contributing with <span className="font-bold">10 or more leads</span> on the app, this badge will be available on your profile.</p>
                }
            </>
        )
    }

    const updateTierAchived = async (newTier: string) => {
        if (!newTier) return
        const token = (await auth.currentUser?.getIdToken(true)) ?? ""
        await sendMessageToAPI({
            token: token,
            route: "profile/update",
            data: {
                id: currentUser.id,
                tierAchieved: newTier,
            },
        })
        addNotificationBanner({ content: <BannerContent badge={newTier} />, id: "", timestamp: Timestamp.now() })
    }

    const value = {
        leads
    }

    return (
        <LeadsContext.Provider value={value}>
            {children}
        </LeadsContext.Provider>
    )
}