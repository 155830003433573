'use client'

import { db } from "@/connectors/firebase";
import { Need } from "@/interfaces/firebase";
import { collection, onSnapshot, query } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import useAuth from "@/hooks/useAuth";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const NeedsContext = React.createContext({
    needs: [] as Need[],
    refresh: async () => Promise<Need[]>
});

export function useNeeds() {
    return useContext(NeedsContext);
}

export function NeedsProvider({ children }: { children: React.ReactNode }) {
    const { currentUser, userLoggedIn } = useAuth()
    const queryClient = useQueryClient()
    const { data } = useQuery(
        {
            queryKey: ["needs"],
            initialData: () => {
                return queryClient.getQueryData(["needs"]) as Need[] ?? []
            },
            staleTime: Infinity
        }
    )
    const [needs, setNeeds] = useState<Need[]>(data)

    useEffect(() => {
        if (data) setNeeds(data)
    }, [])

    useEffect(() => {
        if (needs) queryClient.setQueryData(["needs"], needs);
    }, [needs])

    useEffect(() => {
        if (!userLoggedIn) return
        let unsub = () => { };
        try {
            const needsRef = query(collection(db, "needs"))
            unsub = onSnapshot(needsRef, async (querySnapshot) => {
                let allNeeds: Need[] = []
                for (const snap of querySnapshot.docs) {
                    let data = snap.data() as Need;
                    allNeeds.push({ ...data, id: snap.id })
                }
                setNeeds(allNeeds)
            });
        } catch (error: any) {
            console.log(error)
        }
        return unsub
    }, [currentUser.id, userLoggedIn])

    const value = {
        needs,
        refresh: () => {
            return new Promise<Need[]>((resolve, reject) => {
                const needsRef = query(collection(db, "needs"))
                onSnapshot(needsRef, async (querySnapshot) => {
                    let allNeeds: Need[] = []
                    for (const snap of querySnapshot.docs) {
                        let data = snap.data() as Need;
                        allNeeds.push({ ...data, id: snap.id })
                    }
                    setNeeds(allNeeds)
                    resolve(allNeeds);
                });
            });
        }
    }


    return (
        <NeedsContext.Provider value={value as any}>
            {children}
        </NeedsContext.Provider>
    )
}