"use client"

import React, { createContext, useContext, useState, ReactNode, ReactElement, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { app, auth, onMessageListener } from '@/connectors/firebase';
import { Timestamp } from 'firebase/firestore';
import useAuth from "@/hooks/useAuth";
import { getMessaging, getToken } from 'firebase/messaging';
import { sendMessageToAPI } from '@/services/api';
import { useRouter } from 'next/navigation';


interface NotificationBannerContextProps {
    notificationsBanner: NotificationBannerProps[];
    addNotificationBanner: (notification: NotificationBannerProps) => void;
    removeNotificationBanner: (e: any, id?: string) => void;
}

interface NotificationBannerProps {
    id?: string;
    content: ReactElement;
    action?: string;
    timestamp?: Timestamp;
}

interface NotificationFromFirestore {
    notification: NotificationBodyFromFirestore;
    messageId: string;
    from: string;
    data?: NotificationDataFromFirestore;
}

interface NotificationBodyFromFirestore {
    title: string;
    body: string;
}
interface NotificationDataFromFirestore {
    action: string;
    image: string;
    user: string;
}

const NotificationBannerContext = createContext<NotificationBannerContextProps | undefined>(undefined);

export const useNotificationBanner = () => {
    const context = useContext(NotificationBannerContext);
    if (!context) {
        throw new Error('useNotificationBanner must be used within a NotificationBannerProvider');
    }
    return context;
};

const BannerContent = ({ notification, data }: NotificationFromFirestore): ReactElement => {
    return (
        <>
            <div className="flex flex-row justify-start items-center px-2 py-3 space-x-3">
                {data?.image ? (
                    <div className="flex items-center w-[70px] h-[70px]">
                        <div className="w-[70px] h-[70px] relative overflow-hidden">
                            <img
                                src={data.image}
                                alt="notificationImage"
                                className="rounded-full object-cover w-[70px] h-[70px]"
                            />
                        </div>
                    </div>
                ) : null}
                <div className={`flex flex-col justify-center h-full ${data?.image ? "w-[calc(100%_-_82px)]" : "w-full"}`}>
                    <p className="text-white font-bold text-lg line-clamp-1">{notification.title} IN APP</p>
                    <p className="text-white font-medium text-sm">{notification.body}</p>
                </div>
            </div>
        </>
    )
}

export const NotificationBannerProvider = ({ children }: { children: ReactNode }) => {
    const { currentUser } = useAuth()
    const router = useRouter()
    const [notificationsBanner, setNotificationsBanner] = useState<NotificationBannerProps[]>([]);

    useEffect(() => {
        requestNotificationPermission()
    }, [router])

    const updateDeviceToken = async (device: string) => {
        if (!device || currentUser.device == device) return
        const token = (await auth.currentUser?.getIdToken(true)) ?? "";
        await sendMessageToAPI({
            token: token,
            route: "profile/update",
            data: {
                id: currentUser.id,
                device: device,
            }
        })
    }

    const subscribeNotification = async (device: string, topic: string) => {
        const token = (await auth.currentUser?.getIdToken(true)) ?? "";
        await sendMessageToAPI({
            token: token,
            route: "notification/subscribe",
            data: {
                id: currentUser.id,
                device: device,
                topic: topic,
            },
        });
    }

    const unsubscribeNotification = async (topic: string, device?: string) => {
        if (!device) return
        const token = (await auth.currentUser?.getIdToken(true)) ?? "";
        await sendMessageToAPI({
            token: token,
            route: "notification/unsubscribe",
            data: {
                id: currentUser.id,
                device: device,
                topic: topic,
            },
        });
    }

    const requestNotificationPermission = async () => {
        if ("Notification" in window && typeof Notification.requestPermission === "function") {
            await Notification.requestPermission();
            if (Notification.permission == "granted") {
                const messaging = getMessaging(app)
                getToken(messaging, { vapidKey: "BMJC9xeqIk4yKf2HChTic60sooxPLIGCCAn_NZ81q_KI0RnGUzIH0JdRHh2-ukQvQ0D_aBr1tW-oD4yX5L40Yow" }).then((currentToken) => {
                    updateDeviceToken(currentToken)
                    subscribeNotification(currentToken, "global")
                })
                    .catch((reason) => {
                        console.log(reason)
                    })
            }
            else if (Notification.permission == "denied") {
                unsubscribeNotification("global", currentUser.device)
            }
        }
    }

    onMessageListener()
        .then((payload: any) => {
            console.log(payload)
            const receivedNotify: NotificationFromFirestore = payload;
            if ((!receivedNotify?.data || !receivedNotify?.data.user) || (receivedNotify?.data.user && receivedNotify.data.user == currentUser.id)) {
                addNotificationBanner({
                    content: <BannerContent {...receivedNotify} />,
                    action: receivedNotify.data?.action,
                    timestamp: Timestamp.now()
                });
            }
        })
        .catch((err) => console.log('failed: ', err));

    const addNotificationBanner = async (notification: NotificationBannerProps) => {
        const UUID = uuidv4();
        const newNotification = notification.id ? notification : { ...notification, id: UUID };
        setNotificationsBanner(prevNotifications => [...prevNotifications, newNotification]);
    };

    const removeNotificationBanner = (e: any, id?: string) => {
        e.preventDefault()
        e.stopPropagation()
        if (!id) return
        setNotificationsBanner(prevNotifications => prevNotifications.filter(n => n.id !== id));
    };

    return (
        <NotificationBannerContext.Provider value={{ notificationsBanner, addNotificationBanner, removeNotificationBanner }}>
            {children}
        </NotificationBannerContext.Provider>
    );
};
